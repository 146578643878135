import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import { navigate } from "gatsby";

import uiClose from "./../images/ui-close.png";
import "./modal.scss";

// const Modal = ({ children, className }) => {

class Modal extends React.Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    if (typeof document !== "undefined") {
      document.addEventListener("keydown", this.onKeyPressed);
    }
  }

  componentWillUnmount() {
    if (typeof document !== "undefined") {
      document.removeEventListener("keydown", this.onKeyPressed);
    }
  }
  onKeyPressed(e) {
    switch (e.keyCode) {
      case 27:
        navigate("/");
        break;
    }
  }
  render() {
    let addedClass = this.props.className || false;
    let modalClasses = "modal";
    if (addedClass) {
      modalClasses += ` ${addedClass}`;
    }
    return (
      <>
        <div className={modalClasses}>
          <Link to="/" className="modal__close">
            <img draggable="false" src={uiClose} />
          </Link>
          <div className="modal__scroll-area">
            <div className="modal__content">{this.props.children}</div>
          </div>
        </div>
        <div
          className="modal__bg"
          onClick={() => {
            navigate("/");
          }}
        />
      </>
    );
  }
}

export default Modal;
